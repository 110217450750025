import { AuthContext } from '@core/auth/models/auth-context';
import { LocalRepositoryService } from '@core/storage/local-repository.service';
import { Injectable } from '@angular/core';
import { isNil } from '@core/utils/nil/is-nil';

@Injectable({
  providedIn: 'root',
})
export class AuthContextStorage {
  private readonly TOKEN_STORAGE_KEY = 'a95f37bc-81c3-4b97-b8e3-dc95631b1ad1';
  private readonly REFRESH_TOKEN_STORAGE_KEY =
    '79d6d384-e6db-4485-9219-77ed1990fbe5';

  constructor(private readonly localStorage: LocalRepositoryService) {}

  store(context: AuthContext): void {
    const { token, refreshToken } = context;

    this.storeToken(token);
    this.storeRefreshToken(refreshToken);
  }

  fetch(): AuthContext {
    try {
      const token = this.fetchToken();
      const refreshToken = this.fetchRefreshToken();

      return new AuthContext(token, refreshToken);
    } catch (error) {
      throw new Error('The auth context was not found.');
    }
  }

  fetchToken(): string {
    const token = this.localStorage.getItem(this.TOKEN_STORAGE_KEY);

    if (isNil(token)) {
      throw new Error('The token was not found.');
    }

    return token;
  }

  fetchRefreshToken(): string {
    const refreshToken = this.localStorage.getItem(
      this.REFRESH_TOKEN_STORAGE_KEY
    );

    if (isNil(refreshToken)) {
      throw new Error('The refresh token was not found.');
    }

    return refreshToken;
  }

  clear(): void {
    this.clearToken();
    this.clearRefreshToken();
  }

  private clearToken(): void {
    return this.localStorage.removeItem(this.TOKEN_STORAGE_KEY);
  }

  private clearRefreshToken(): void {
    return this.localStorage.removeItem(this.REFRESH_TOKEN_STORAGE_KEY);
  }

  private storeToken(token: string): void {
    this.localStorage.setItem(this.TOKEN_STORAGE_KEY, token);
  }

  private storeRefreshToken(token: string): void {
    this.localStorage.setItem(this.REFRESH_TOKEN_STORAGE_KEY, token);
  }
}
