import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class ResetPasswordRequest {
  @jsonMember readonly password!: string;
  @jsonMember readonly repeatPassword!: string;

  constructor(password: string, repeatPassword: string) {
    this.password = password;
    this.repeatPassword = repeatPassword;
  }
}
