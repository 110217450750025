import { Injectable } from '@angular/core';
import { catchError, map, Observable, switchMap, tap, throwError } from 'rxjs';
import { UserContext } from '@core/auth/models/user-context';
import { SignInRequest } from '@api/auth/requests/sign-in.request';
import { AuthApiService } from '@api/auth/auth-api.service';
import { AuthContext } from '@core/auth/models/auth-context';
import { AuthContextStorage } from '@core/auth/storages/auth-context.storage';
import { UserContextStorage } from '@core/auth/storages/user-context.storage';
import { UserContextConverter } from '@core/auth/converters/user-context.converter';
import { HttpErrorHandler } from '@core/http/handlers/http-error.handler';
import { AuthErrorResponse } from '@api/common/responses/auth-error.response';
import { isNil } from '@core/utils/nil/is-nil';
import { RecoverPasswordRequest } from '@api/auth/requests/recover-password.request';
import { ResetPasswordRequest } from '@api/auth/requests/reset-password.request';
import { RefreshTokenRequest } from '@api/auth/requests/refresh-token.request';
import { SignOutRequest } from '@api/auth/requests/sign-out.request';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly authApi: AuthApiService,
    private readonly authContextStorage: AuthContextStorage,
    private readonly userContextStorage: UserContextStorage,
    private readonly userContextConverter: UserContextConverter,
    private readonly httpErrorHandler: HttpErrorHandler
  ) {}

  signInByCredentials(
    email: string,
    password: string
  ): Observable<UserContext> {
    const request = new SignInRequest(email, password);

    return this.authApi.signInByCredentials(request).pipe(
      catchError(this.httpErrorHandler.handleAuthError()),
      catchError(({ message }: AuthErrorResponse) =>
        throwError(() => new Error(message ?? 'Wystąpił nieznany błąd.'))
      ),
      map(({ token, refreshToken }) => new AuthContext(token, refreshToken)),
      tap(context => this.authContextStorage.store(context)),
      switchMap(() => this.fetchUserContext())
    );
  }

  recoverPassword(email: string): Observable<void> {
    const request = new RecoverPasswordRequest(email);

    return this.authApi.recoverPassword(request).pipe(
      catchError(this.httpErrorHandler.handleAuthError()),
      catchError(({ message }: AuthErrorResponse) =>
        throwError(() => new Error(message ?? 'Wystąpił nieznany błąd.'))
      )
    );
  }

  resetPassword(
    token: string,
    password: string,
    repeatPassword: string
  ): Observable<void> {
    const request = new ResetPasswordRequest(password, repeatPassword);

    return this.authApi.resetPassword(request, token).pipe(
      catchError(this.httpErrorHandler.handleAuthError()),
      catchError(({ message }: AuthErrorResponse) =>
        throwError(() => new Error(message ?? 'Wystąpił nieznany błąd.'))
      )
    );
  }

  signOut(): Observable<void> {
    return new Observable<void>(subject => {
      try {
        const token = this.authContextStorage.fetchRefreshToken();

        this.authApi.signOut(new SignOutRequest(token)).subscribe();
      } catch (error) {
        console.error(error);
      }

      this.authContextStorage.clear();
      this.userContextStorage.clear();

      subject.next();
      subject.complete();
    });
  }

  isLoggedIn(): Observable<boolean> {
    return this.userContextStorage
      .fetchAsync()
      .pipe(map(context => !isNil(context)));
  }

  refreshToken(): Observable<AuthContext> {
    const refreshToken = this.authContextStorage.fetchRefreshToken();
    const request = new RefreshTokenRequest(refreshToken);

    return this.authApi.refresh(request).pipe(
      map(response => new AuthContext(response.token, response.refreshToken)),
      tap(context => this.authContextStorage.store(context))
    );
  }

  fetchUserContext(): Observable<UserContext> {
    return this.authApi.me().pipe(
      map(response => this.userContextConverter.modelByResponse(response)),
      tap(context => this.userContextStorage.store(context))
    );
  }

  fetchAuthContext(): Observable<AuthContext> {
    return new Observable<AuthContext>(subject => {
      try {
        const context = this.authContextStorage.fetch();

        subject.next(context);
        subject.complete();
      } catch (error) {
        subject.error(error);
      }
    });
  }
}
