import { HttpErrorResponse } from '@angular/common/http';
import { JsonTransformerService } from '@core/json/services/json-transformer.service';
import { Observable, throwError } from 'rxjs';
import { AuthErrorResponse } from '@api/common/responses/auth-error.response';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorHandler {
  constructor(private readonly jsonTransformer: JsonTransformerService) {}

  handleAuthError(): (response: HttpErrorResponse) => Observable<never> {
    return (response: HttpErrorResponse) => {
      const { error } = response;

      return throwError(() =>
        this.jsonTransformer.toObject(error, AuthErrorResponse)
      );
    };
  }
}
