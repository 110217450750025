import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class SignInRequest {
  @jsonMember({ name: 'username' }) readonly email!: string;
  @jsonMember readonly password!: string;

  constructor(email: string, password: string) {
    this.email = email;
    this.password = password;
  }
}
