import { Injectable } from '@angular/core';
import { UserContext } from '@core/auth/models/user-context';
import { MeResponse } from '@api/auth/responses/me.response';

@Injectable({
  providedIn: 'root',
})
export class UserContextConverter {
  modelByResponse(response: MeResponse): UserContext {
    return new UserContext(response.id, response.email);
  }
}
