import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiHelperService } from '@api/common/services/api-helper.service';
import { ApiUrlFactory } from '@api/common/factories/api-url.factory';
import { HttpContextBuilder } from '@core/http/builders/http-context.builder';
import { map, Observable } from 'rxjs';
import { SignInRequest } from '@api/auth/requests/sign-in.request';
import { SignInResponse } from '@api/auth/responses/sign-in.response';
import { environment } from '@environments/environment';
import { MeResponse } from '@api/auth/responses/me.response';
import { RecoverPasswordRequest } from '@api/auth/requests/recover-password.request';
import { blank } from '@core/utils/rxjs/blank';
import { ApiParams } from '@environments/enums/api-params';
import { ResetPasswordRequest } from '@api/auth/requests/reset-password.request';
import { RefreshTokenRequest } from '@api/auth/requests/refresh-token.request';
import { RefreshTokenResponse } from '@api/auth/responses/refresh-token.response';
import { SignOutRequest } from '@api/auth/requests/sign-out.request';

const { AUTH } = environment.api;

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly helper: ApiHelperService,
    private readonly contextBuilder: HttpContextBuilder,
    private readonly apiUrlFactory: ApiUrlFactory
  ) {}

  signInByCredentials(request: SignInRequest): Observable<SignInResponse> {
    const { SIGN_IN, HOST } = AUTH;
    const url = this.apiUrlFactory.simple(HOST, SIGN_IN);
    const body = this.helper.requestToBody(request, SignInRequest);
    const context = this.contextBuilder
      .skipByRefreshToken()
      .skipByAuthToken()
      .build();

    return this.http
      .post(url, body, { context })
      .pipe(map(this.helper.bodyToResponse(SignInResponse)));
  }

  signOut(request: SignOutRequest): Observable<void> {
    const { SIGN_OUT, HOST } = AUTH;
    const url = this.apiUrlFactory.simple(HOST, SIGN_OUT);
    const body = this.helper.requestToBody(request, SignOutRequest);
    const context = this.contextBuilder.skipByRefreshToken().build();

    return this.http.post(url, body, { context }).pipe(blank());
  }

  recoverPassword(request: RecoverPasswordRequest): Observable<void> {
    const { RECOVER_PASSWORD, HOST } = AUTH;
    const url = this.apiUrlFactory.simple(HOST, RECOVER_PASSWORD);
    const body = this.helper.requestToBody(request, RecoverPasswordRequest);
    const context = this.contextBuilder
      .skipByRefreshToken()
      .skipByAuthToken()
      .build();

    return this.http.post(url, body, { context }).pipe(blank());
  }

  resetPassword(
    request: ResetPasswordRequest,
    token: string
  ): Observable<void> {
    const { RESET_PASSWORD, HOST } = AUTH;
    const url = this.apiUrlFactory.withParams(HOST, RESET_PASSWORD, {
      [ApiParams.ActivationToken]: token,
    });
    const body = this.helper.requestToBody(request, ResetPasswordRequest);
    const context = this.contextBuilder
      .skipByRefreshToken()
      .skipByAuthToken()
      .build();

    return this.http.post(url, body, { context }).pipe(blank());
  }

  me(): Observable<MeResponse> {
    const { ME, HOST } = AUTH;
    const url = this.apiUrlFactory.simple(HOST, ME);

    return this.http.get(url).pipe(map(this.helper.bodyToResponse(MeResponse)));
  }

  refresh(request: RefreshTokenRequest): Observable<RefreshTokenResponse> {
    const { REFRESH, HOST } = AUTH;
    const url = this.apiUrlFactory.simple(HOST, REFRESH);
    const body = this.helper.requestToBody(request, RefreshTokenRequest);
    const context = this.contextBuilder
      .skipByRefreshToken()
      .skipByAuthToken()
      .build();

    return this.http
      .post(url, body, {
        context,
      })
      .pipe(map(this.helper.bodyToResponse(RefreshTokenResponse)));
  }
}
